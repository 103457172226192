<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="L['我的供应商']"></MemberTitle>
      <div class="container">
        <h3>{{"添加供应商"}}</h3>
        <div>
          <div class="headerSearch2">
            <el-form
              ref="ruleFormRef"
              :model="param"
              :rules="rules"
              status-icon
               class="demo-ruleForm"
            >
              <el-form-item class="form-Input" label="公司名称" :label-width="formLabelWidth" prop="supplierName" >
                <el-input placeholder="请输入公司名称" v-model="param.supplierName" autocomplete="off" />
              </el-form-item>
              <el-form-item class="form-Input" label="联系人" :label-width="formLabelWidth" prop="userName" >
                <el-input placeholder="请输入联系人" v-model="param.userName" />
              </el-form-item>
              <el-form-item class="form-Input" label="联系电话" :label-width="formLabelWidth" prop="phone" >
                <el-input placeholder="请输入联系电话" v-model="param.phone" />
              </el-form-item>
              <el-form-item class="form-Input" label="联系邮箱" :label-width="formLabelWidth" prop="email" >
                <el-input placeholder="请输入联系邮箱"  v-model="param.email" />
              </el-form-item>
              <!--<el-form-item>-->
              <div class="formBtn">
                <div class="button" @click="addSupplier(ruleFormRef)">确定</div>
                <el-button @click="resetForm(ruleFormRef)">取消</el-button>
              </div>
              <!--</el-form-item>-->
            </el-form>
          </div>
          
        </div>
      </div>
    </div>
    <!--<el-dialog
      top="20%"
      v-model="dialogVisible"
      
      width="20%"
      :before-close="handleClose"
    >
   
     <div v-if="msg" class="result">
      <img src="@/assets/supplier_error.png" alt="">
      <h2>提交失败</h2>
      <p style="line-height: 1.5;marginTop:10px">{{msg}}</p>
     </div>
     <div v-if="!msg" class="result">
      <img src="@/assets/supplier_succes.png" alt="">
      <h2>提交成功</h2>
      <div class="content">
      <p>您的供应商邀请已提交成功,</p>
      <p>邀请信息已发送至邮箱，请供应商登录邮箱查看。</p>
      </div>
     </div>
    </el-dialog>-->
    <div class="dialog">
    <el-dialog v-model="dialogVisible" :show-close="false"
     top="15%" width="30%" :close-on-click-modal="false">
    <div>
      <!--<Slots>-->
        <div class="my-header">
          <div class="modalTitle"><img src="../../../assets/supplier_warning.png" alt="" ><span>提示</span></div>
          <div style="font-size:24px;color:rgb(163 158 158);cursor: pointer;" @click="closeDialog">×</div>
        </div>
      <!--</Slots> -->
      <div v-if="code!==200" class="result">
          <img src="@/assets/supplier_error.png" alt="">
          <h2>提交失败</h2>
          <p style="line-height: 1.5;marginTop:10px">{{msg}}</p>
        </div>
        <div v-if="code==200" class="result">
          <img src="@/assets/supplier_succes.png" alt="">
          <h2>提交成功</h2>
          <div class="content">
          <p>您的供应商邀请已提交成功,</p>
          <p>邀请信息已发送至邮箱，请供应商登录邮箱查看。</p>
          </div>
        </div>
        </div>
      <!--This is dialog content.-->
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
  ElDialog 
} from "element-plus";
import { ElButton, } from 'element-plus'
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";

export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const formLabelWidth = "80px";
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const ruleFormRef = ref();
    const orderSn = ref(""); //订单id
    let orderProductObj = reactive({});
    const dialogVisible = ref(false)
    let orderProductList = reactive({ data: [] }); //确认收货展示数据
    const tableData = reactive({ data: [] });
    const param = reactive({
      //memberId: store.state.memberInfo.memberId,
      supplierName: "",
      phone: "",
      userName: "",
      email: "",
    });
    const msg=ref("")
    const code=ref("")
    //const pageData = reactive({
    //  pageNum: 1,
    //  pageSize: 10,
    //  total: 0,
    //});
    const rules = {
        supplierName: [
          { required: true, message: "请输入公司姓名", trigger: "change" },
          //{ min: 2, max: 25, message: "请输入2~25个字符", trigger: "change" }
        ],
        userName: [
          { required: true, message: "请输入联系人", trigger: "change" },
          //{ min: 2, max: 25, message: "请输入2~25个字符", trigger: "change" }
        ],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "change" },
          {
            pattern: /^((\+?86)|(\(\+86\)))?(1[3-9]\d{9}$)|(\d{4}-)\d{6,8}$/,
            message: "请输入正确的电话号码",
            trigger: "change"
          }
        ],
        email: [
          { required: true, message: "请输入联系邮箱", trigger: "change" },
          {
            pattern:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "请输入正确的邮箱号码",
            trigger: "change"
          }
        ],
      }; //输入校验

    const addSupplier = (formEl) => {
       if (!formEl) return
    formEl.validate((valid) => {
    if (valid) {
     proxy.$post("api/shopp/supplier/addSupplier", param,"json").then((res) => {
        code.value=res.code
        if (res.code == 200) {
          dialogVisible.value=true
        }else{
           dialogVisible.value=true
          msg.value=res.msg

          console.log();
        }
      });
    } else {
      console.log('error submit!')
      return false
    }
  })
      
    
      console.log(dialogVisible);
      console.log("submit!");
    };
    //const handleClose=()=>{
    //  router.go(-1);
    //}
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
      router.go(-1);
      //param.resetFields()
      //param.planCode= "",
      // param. source= "",
      // param. prayName= "",
      // param. startDate= "",
      // param. endDate= "",
      //  param.resource= "",
      //  param.state= "",
      //getOrderList()
    };
    const closeDialog=()=>{
      if(code.value!==200){
         dialogVisible.value=false;
      }else{
        router.go(-1);
        dialogVisible.value=false;
      }
      
       
    }
    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      //  getTime();
      //  getCancelList();
      //  getAddressList();
    });
    
    router.beforeEach((to, from, next) => {
      //  if (to.query.orderState) {
      //    current_state.value = to.query.orderState;
      //  } else {
      //    current_state.value = "";
      //  }
      //  if (to.query.evaluateState) {
      //    evaluate_state.value = to.query.evaluateState;
      //  } else {
      //    evaluate_state.value = "";
      //  }
      if (to.path == "/member/procurement/procurementPlanList") {
        //getOrderList();
      }
      next();
    });
    return {
      L,
      addSupplier,
      param,
      resetForm,
      dialogVisible,
      formLabelWidth,
      ruleFormRef,
      rules,
      msg,
      //handleClose,
      closeDialog,code
    };
  },
};
</script>
<style lang="scss" >
/*@import "../../../style/orderList.scss";*/
/*@import "../../../style/historiclStatement.scss";*/
@import "../../../style/addSupplier.scss";

/*.el-dialog__header {
    padding: var(--el-dialog-padding-primary);
    padding-bottom: 10px; 
   
}*/

.el-pager li.active {
  color: $colorMain;
  cursor: default;
  font-size: 16px;
}
.look {
  color: rgb(74, 122, 211);
  cursor: pointer;
}
.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

/*.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}*/
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.sld_order_button {
  margin-bottom: 10px;
}
.el-radio {
  margin-right: 0 !important;
}
.button {
  background: #0e3177;
  cursor: pointer;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 20px;
}
.addButton {
  background: #0e3177;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.headerSearch2 {
  width:50%;
  margin:100px auto;
}
.searchBtn {
  display: flex;
  width: 200px;
  position: absolute;
  top: 1%;
  left: 84%;
}
.greenCircle{
  width:10px;
  height:10px;
  background-color: rgb(56, 198, 56);
  border-radius: 50%;
  display:inline-block;
  margin-right:10px;
}
.redCircle{
  width:10px;
  height:10px;
  background-color:red;
  border-radius: 50%;
  display:inline-block;
  margin-right:10px;
}
.formBtn{
  width:100%;
  display: flex;
  margin-top:50px;
  justify-content: center;
}
.result{
  height: 100%;
  margin-top: 30px;
  text-align: center;
  padding:0px 20px 50px 20px;
  h2{
    margin:10px  0 25px 0;
  }
  .content{
    margin-top: 10px;
    color:#969090;
    line-height: 1.7;
    font-size: 17px;
  }
  
  img{
    width: 57px;
    height:57px;
    
  }
}

.modalTitle{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  img{
    width:27px;
    height:24px;
    /*margin-right: 5px;*/
  }
}
/*.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px !important;
    padding-left:0px;
    padding-right:0px;
    /*margin:13px 10px 30px 10px;*/
/*}*/
.form-Input{
  margin-bottom:53px;
}
.dialog{
.el-dialog{
  border-radius: 8px;
}
}
</style>